import {
  validationMixin,
  DocumentTypeService,
  CompanyService
} from "@zoox-framework/smck-service";
import Vue from "vue";
export default {
  name: "zPayLoginCard",
  mixins: [validationMixin],
  props: {
    buttons: {
      type: Array
    },
    cardTitle: {
      type: String
    },
    backButton: {
      type: Boolean
    }
  },

  data() {
    return {
      companyService: new CompanyService(),
      showPassword: false,
      loginData: {
        documentTypeId: null,
        documentNumber: null,
        password: null
      },
      companyParams: {},
      requiredDocuments: {
        cpf: true,
        passport: true
      }
    };
  },
  async created() {
    const company = this.companyService.getLocalCompany();
    this.companyParams = await this.companyService.getFnrhFormSettings(
      company.id
    );
  },
  computed: {
    documentTypes: function() {
      return new DocumentTypeService(Vue).getAll(this.requiredDocuments);
    }
  },
  methods: {
    getDocumentMask: function(documentType) {
      switch (documentType) {
        case 1: // CPF
          return "###.###.###-##";
        case 5: // RUT
          return "#######-N";
        default:
          return "";
      }
    },
    getDocumentLength: function(documentType) {
      switch (documentType) {
        case 4: // Passport
          return 12;
        default:
          return 20;
      }
    },
    getDocumentValidation: function(documentType, value) {
      let isValid = true;

      isValid = isValid && this.rules.required(value);

      switch (documentType) {
        case 1: // CPF
          isValid = isValid && this.rules.cpf(value);
          break;
        case 5: // RUT
          isValid = isValid && this.rules.rut(value);
          break;
        default:
          break;
      }

      return isValid;
    },
    async login(loginData) {
      this.$emit("login", loginData);
    }
  }
};
