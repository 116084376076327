// import zPayPinForm from "../zPayPinForm";
import { mapState } from "vuex";
import { zPayPinForm } from "@zoox-framework/zoox-components";

export default {
  name: "zPayEntryOptions.js",
  components: {
    "z-pay-pin-form": zPayPinForm
  },
  computed: {
    ...mapState(["virtualWallet"])
  },
  data() {
    return {};
  },
  methods: {}
};
