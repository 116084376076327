export default {
  name: "zPayActionCard",
  props: {
    buttons: {
      type: Array
    },
    cardTitle: {
      type: String
    },
    backButton: {
      type: Boolean
    },
    cards: {
      type: Array
    }
  }
};
