import PincodeInput from "vue-pincode-input";
import { ApiClient } from "@zoox-framework/zoox-infra";

import { regionMixin } from "@zoox-framework/smck-service";

export default {
  name: "zValidationGuestModal",
  mixins: [regionMixin],
  props: {
    validationModalStep: {
      type: Number,
      default: null
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    componentClient: {
      type: String,
      default: "portal"
    },
    radioGroupSelectValidationModel: {
      type: Object,
      default: () => null
    },
    radioGroupSelectValidationLabels: {
      type: Array,
      default: () => null
    },
    guestComunicationOptions: {
      type: Array,
      default: () => null
    }
  },
  components: {
    PincodeInput
  },
  data() {
    return {
      validationCode: "",
      canCheckCode: false,
      guestComunicationOptionsObj: null,
      apiClient: new ApiClient(),
      validationModalStepObj: 0,
      radioGroupSelectValidationModelObj: null,
      render: false,
      countries: []
    };
  },
  async created() {
    this.validationModalStepObj = 0;
    await this.apiClient.get("open/country/list").then(resp => {
      this.countries = resp;
    });
  },
  mounted() {},
  watch: {
    validationCode(value) {
      if (value && value.length >= 5) {
        this.canCheckCode = true;
        this.checkValidationCode();
      } else {
        this.canCheckCode = false;
      }
    },
    validationModalStep(value) {
      this.validationModalStepObj = value;
      this.$emit("update-step", value);
      setTimeout(() => this.focusInputPin(), 10);
    },
    guestComunicationOptions(value) {
      this.guestComunicationOptionsObj = value;
      this.render = true;
    }
  },
  methods: {
    focusInputPin() {
      if (this.validationModalStepObj === 2) {
        this.$refs.pin.focusCellByIndex(0);
      }
    },
    sendCode() {
      this.checkboxValidation();
      this.$emit("send-code", this.radioGroupSelectValidationModelObj);
    },
    checkValidationCode() {
      this.$emit("check-code", {
        code: this.validationCode,
        contactId: this.radioGroupSelectValidationModelObj.id
      });
      this.validationCode = "";
    },
    resendCode() {
      this.validationCode = null;
      this.$emit("resend-code", this.radioGroupSelectValidationModelObj);
    },
    checkboxValidation() {
      this.radioGroupSelectValidationModelObj == null
        ? this.$toaster.warning(this.$t("GLOBAL.BLANK_CHECKBOX"))
        : null;
    },
    fill_new() {
      this.$emit("newfnrh");
    },
    getFlag: function(ddi) {
      const country = this.countries.find(c => c.phoneDial == ddi);
      if (country) {
        return require(`@/assets/images/country-flags/${country.code.toLowerCase()}.svg`);
      } else {
        return "";
      }
    },
    gotoStep(step) {
      this.validationModalStepObj = step;
      this.$emit("update-step", step);
      document.querySelector("input.vue-pincode-input").focus();
    }
  }
};
