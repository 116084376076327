export default {
  name: "zOperationalSystemModal",
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
