import Vue from "vue";
import { validationMixin } from "@zoox-framework/smck-service";
import PersonService from "@/services/PersonService";
import PaymentService from "@/services/PaymentService";
import OrderService from "@/services/OrderService";
import CompanyService from "@/services/CompanyService";
import PaymentHeader from "@/components/PaymentHeader/PaymentHeader.vue";
import zPayActionCard from "@/components/zPayActionCard";
import zPayEntryOptions from "@/components/zPayEntryOptions";
import zPayCameraModal from "@/components/zPayCameraModal";
import zOperationalSystemModal from "@/components/zOperationalSystemModal";
import zPayLoginCard from "@/components/zPayLoginCard";
import { ApiClient } from "@zoox-framework/zoox-infra";
import zValidationGuestModal from "@/components/zValidationGuestModal";
import { zPayLoginClientForm, zCamera } from "@zoox-framework/zoox-components";

export default {
  name: "LoginClient",
  components: {
    zPayLoginClientForm,
    zCamera,
    zPayCameraModal,
    zValidationGuestModal,
    zOperationalSystemModal,
    zPayEntryOptions,
    zPayLoginCard,
    PaymentHeader,
    zPayActionCard
  },
  mixins: [validationMixin],
  data() {
    return {
      noCharges: false,
      vueInstance: Vue,
      personService: new PersonService(),
      apiClient: new ApiClient(),
      paymentService: new PaymentService(),
      companyService: new CompanyService(),
      personId: "",
      personName: "",
      validateReconModal: false,
      key: 1,
      step: 1,
      loginData: {
        documentTypeId: "",
        documentNumber: "",
        password: ""
      },
      password: "",
      isIncorrectPassword: false,
      companyParams: null,
      fullscreen: false,
      chargeToken: null,
      camera: null,
      openCamera: false,
      showValidationModal: false,
      showOperationalSystemModal: false,
      isMobile: false,
      selectedModalGuest: {
        reservationId: "",
        guestId: ""
      },
      radioGroupSelectValidationModel: null,
      radioGroupSelectValidationLabels: [
        this.$t("PIN.SEND_MAIL"),
        this.$t("PIN.SEND_SMS")
      ],
      validationModalStep: 0,
      validationCode: null,
      validationModalGuest: null,
      showBrowserModalRecomendation: false,
      showBrowserModalAlreadyFinished: false,
      guestComunicationOptions: null,
      order: {},
      charges: [],
      currency: "BRL",
      debits: [],
      expiredCharge: false
    };
  },
  computed: {
    firstStepBtn() {
      return [
        {
          title: this.$t("PAY_LOGIN.ALREADY_REGISTERED_BUTTON"),
          action: "Login"
        },
        {
          title: this.$t("PAY_LOGIN.WANT_SIGN_UP_BUTTON"),
          action: "Register"
        }
      ];
    },
    secondStepBtn() {
      return [
        {
          title: this.$t("PAY_LOGIN.FACIAL_RECOGNITION_BUTTON_LOGIN"),
          action: "FacialRecognition"
        },
        {
          title: this.$t("PAY_LOGIN.DOCUMENT_LOGIN_BUTTON"),
          action: "TraditionalLogin"
        }
      ];
    }
  },
  beforeCreate() {
    localStorage.removeItem("charge_token");
    localStorage.removeItem("order");
  },
  async created() {
    let charge;
    this.personService.logout();
    this.chargeToken = this.$route.query.chargeToken;
    let token = await this.paymentService.getChargeToken();

    if (!token && this.chargeToken) {
      await this.paymentService.setChargeToken(this.$route.query.chargeToken);
      token = await this.paymentService.getChargeToken();
    }

    if (!token) {
      return;
    }

    const orderService = new OrderService();
    await orderService
      .getChargeByToken(token)
      .then(resp => {
        charge = resp;
      })
      .catch(ex => {
        switch (ex.status) {
          case 404:
            this.noCharges = true;
            this.step = 1;
            charge = null;
            break;
          case 408:
            this.expiredCharge = true;
            break;
          default:
            this.noCharges = true;
            this.step = 1;
            charge = null;
            break;
        }
      });

    if (charge) {
      const orderId = charge.order.id;
      this.order = await orderService.getOrderById(orderId);
    }

    if (token) {
      if (this.order != null) {
        this.order.orderId = this.order.id || this.order._id;
        this.currency = this.order.currency || this.currency;
        this.charges = await orderService.getCharges(this.order.orderId, {
          status: "pending"
        });
      }
      if (this.charges.length > 0) {
        let itemsIds = [];
        this.charges[0].items.forEach(function(item) {
          itemsIds.push(item.id);
        });
        const items = await orderService.getBulkItems(itemsIds);
        items.forEach(item => {
          const percentLeft = item.percentLeft || 100;
          const itemValue = (item.amount * item.quantity * percentLeft) / 100;
          this.debits.push({
            name: item.description,
            price: itemValue
          });
        });
      } else {
        this.noCharges = true;
        this.step = 1;
      }
    } else {
      this.noCharges = true;
      this.step = 1;
    }
  },
  async mounted() {
    const companyId = await this.companyService.getCurrentCompanyId();
    this.companyParams = await this.companyService.getFnrhFormSettings(
      companyId
    );
  },
  methods: {
    checkDevice() {
      if (navigator.userAgent.match(/Android/i) && window.innerWidth < 600) {
        this.showOperationalSystemModal = true;
        this.isMobile = true;
      } else {
        this.showValidationModal = true;
      }
    },
    openValidationModal(guest) {
      this.validationModalGuest = guest;
      this.showValidationModal = true;
    },
    closeOperationalSystemModal() {
      this.showOperationalSystemModal = false;
      this.openValidationModal(this.selectedGuest);
    },
    closeValidationModal() {
      this.showValidationModal = false;
      this.validationModalStep = 0;
      this.validationModalGuest = null;
      this.radioGroupSelectValidationModel = null;
      this.showError = false;
    },
    async resendCode(radioGroupSelectValidationModelObj) {
      return this.sendCode(radioGroupSelectValidationModelObj);
    },
    async sendCode(radioGroupSelectValidationModelObj) {
      if (radioGroupSelectValidationModelObj) {
        try {
          const resp = await this.apiClient.post(`person/send-code`, {
            personId: this.personId,
            language: this.globalLocale,
            companyId: await this.companyService.getCurrentCompanyId(),
            contactId: radioGroupSelectValidationModelObj.id
          });

          if (resp.ok) {
            this.validationModalStep = 2;
            this.showError = false;
            this.$toaster.success(this.$t("PIN.RESEND_CODE"));
          }
        } catch (e) {
          this.$toaster.warning(this.$t("PIN.WAIT_90_SECONDS"));
          console.error(e);
        }
      }
    },
    async getSelectedGuestPersonId() {
      const { reservationId, guestId, personId } = this.selectedModalGuest;
      if (personId != null) {
        if (
          !this.guestComunicationOptions &&
          this.selectedModalGuest.contactInfoList
        )
          this.guestComunicationOptions = this.selectedModalGuest.contactInfoList;
        else {
          this.guestComunicationOptions = (
            await this.reservationService.getPersonId(reservationId, guestId)
          ).contactInfoList;
        }
        return personId;
      }

      const resp = await this.reservationService.getPersonId(
        reservationId,
        guestId
      );

      if (resp !== "Person not found.") {
        this.guestComunicationOptions = resp.contactInfoList;
        return resp.personId;
      }
      return -1;
    },
    async open(guest) {
      this.selectedGuest = { ...guest };
      this.selectedModalGuest = {
        reservationId: this.currentReservation.id,
        guestId: guest.id,
        personId: guest.personId,
        contactInfoList: guest.contactInfoList
      };

      this.selectedModalGuest.personId = await this.getSelectedGuestPersonId();
      if (this.selectedModalGuest.personId !== -1) {
        this.checkDevice();
      } else {
        this.$redirectGuestView(
          this.selectedModalGuest.reservationId,
          this.selectedModalGuest.guestId,
          false
        );
      }
    },
    reconSuccessForUserRegistered: function(resp) {
      this.openFaceRecognitionModalForUserRegistered = false;
      this.guestComunicationOptions = resp.resp.lastReservations[0].guests.filter(
        x => x.hasOwnProperty("contactInfoList")
      )[0].contactInfoList;
      this.showValidationModal = true;
    },
    reconFailForUserRegistered: function() {
      this.openFaceRecognitionModalForUserRegistered = false;
    },
    checkValidationCode(validationObj) {
      if (validationObj.code && validationObj.code.length === 5) {
        this.loading = true;
        return this.apiClient
          .post(
            `person/verify-code`,
            {
              contactId: validationObj.contactId,
              verificationCode: validationObj.code
            },
            null,
            false,
            false
          )
          .then(async resp => {
            if (resp.token) {
              await this.onLoginSuccess();
            }
          })
          .catch(error => {
            this.loading = false;
            if (error.body.detail === "Incorrect PIN.") {
              this.$toaster.error(this.$t("NOVO_FLUXO.INCORRECT_PIN"));
            } else {
              this.$toaster.error(this.$t("NOVO_FLUXO.UNKNOW_ERROR_PIN"));
            }
          });
      }
    },

    goToFillFnrhWithoutRecognition() {
      this.closeValidationModal();
      this.editGuest(this.selectedGuest);
    },

    goToRegister() {
      this.$router.push("/auth/register-client");
    },
    goToPaymentsRequestsDirect() {
      this.$router.push("/payment/solicitations/direct");
    },
    async login(loginData) {
      const resp = await this.personService.login(loginData);
      if (resp && resp.id) {
        this.personId = resp.id;
        this.personName = resp.name;
        let contactList = await this.personService.getContactsList(
          this.personId,
          this.personName
        );
        const loginObj = {
          personId: this.personId,
          personName: this.personName,
          contactList: contactList
        };
        this.documentLoginSuccess(loginObj);
      }

      if (resp && resp.status === 412) {
        this.$toaster.error(this.$t("PAY_FORGOT_PASSWORD.HAS_NO_PAY"));
      } else if (resp && resp.status === 401) {
        this.$toaster.error(this.$t("PAY_LOGIN.INVALID_PASSWORD_MESSAGE"));
      }
    },
    async capture(image) {
      await this.personService
        .facialLogin(image)
        .then(async resp => {
          if (resp && resp.id) {
            this.personId = resp.id;
            this.personName = resp.name;
            let contactList = await this.personService.getContactsList(
              this.personId,
              this.personName
            );
            const loginObj = {
              personId: this.personId,
              personName: this.personName,
              contactList: contactList
            };
            this.faceReconSuccess(loginObj);
            this.stopCamera();
          }
        })
        .catch(ex => {
          switch (ex.status) {
            case 422:
              this.$toaster.error(this.$t("PAY_LOGIN.NO_FACES_FOUND_IN_PIC"));
              break;
            case 401:
              this.$toaster.error(this.$t("PAY_LOGIN.NO_FACES_FOUND"));
              this.stopCamera();
              break;
            case 404:
              this.$toaster.error(this.$t("PAY_LOGIN.PERSON_NOT_FOUND"));
              this.stopCamera();
              break;
            default:
              this.$toaster.error(this.$t("PAY_LOGIN.RECOGNITION_ERROR"));
              this.stopCamera();
              break;
          }
        });
    },
    stopCamera() {
      this.openCamera = false;
    },
    async onLoginSuccess() {
      const orderService = new OrderService();
      const token = await this.paymentService.getChargeToken();
      const $context = this;
      if (token && !this.expiredCharge && !this.noCharges) {
        await this.paymentService.updatePersonCharge(
          this.personId,
          token,
          this.personName
        );
        const charge = await orderService.getChargeByToken(token);
        const orderId = charge.order.id;
        const order = await orderService.getOrderById(orderId);
        $context.$store.dispatch("$setCurrentCharge", charge);
        await orderService.setLocalOrder(order);
        await this.$router.push("/payment/solicitations");
      } else {
        await this.$router.push("/payment/history");
      }
    },
    async faceReconSuccess(person) {
      this.personId = person.personId;
      this.personName = person.personName;
      this.guestComunicationOptions = person.contactList;
      this.checkDevice();
    },
    async documentLoginSuccess(person) {
      this.personId = person.personId;
      this.personName = person.personName;
      this.guestComunicationOptions = person.contactList;
      this.checkDevice();
    }
  }
};
